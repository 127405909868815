import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintWarg,
  mintPixaNFT,
  tokenURIfinder,
  checkPixaBalance,
  mintThreeWarg,
  checkWargsMinted,
  claimVortical,
  claimVorticalLoop,
  upgradeGem,
  checkDopplers
} from "./utils/interact.js";
import pixagif from 'D:/NFT_development/front_end/new-app-pages/mint/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo.gif'
import pixapng from 'D:/NFT_development/front_end/new-app-pages/mint/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo-2.png'
import wargsgif from 'D:/NFT_development/front_end/new-app-pages/mint/nft-minter-tutorial-main/minter-starter-files/src/PixaWargs.gif'
import wyvernsgif from 'D:/NFT_development/front_end/new-app-pages/mint/nft-minter-tutorial-main/minter-starter-files/src/wyverns.gif'
import wizardsgif from 'D:/NFT_development/front_end/new-app-pages/mint/nft-minter-tutorial-main/minter-starter-files/src/wizards.gif'
import witchesgif from 'D:/NFT_development/front_end/new-app-pages/mint/nft-minter-tutorial-main/minter-starter-files/src/witches.gif'
import charmgif from 'D:/NFT_development/front_end/new-app-pages/mint/nft-minter-tutorial-main/minter-starter-files/src/charm-crystal.gif'
import pleasewait from 'D:/NFT_development/front_end/new-app-pages/mint/nft-minter-tutorial-main/minter-starter-files/src/pleasewait1.PNG'

const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [ownership, setAreYouOwner] = useState("");
  const [upgradestatus, setupgradeStatus] = useState("");
  const [pixaBalance, setPixaBalance] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [wargs, setWargsMinted] = useState("");
  const [displayRank, setRank] = useState("");
  const [tokenID, setToken] = useState("");
  const [tokenURI, setTokenURI] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    const {currentwargsminted} = await checkWargsMinted();
    setWallet(address);
    setStatus(status);
    setWargsMinted(currentwargsminted)
    setTokenURI(wargsgif);
    setupgradeStatus("currently selected.")    
    const {walletpixabalance} = await checkPixaBalance(address)
    setPixaBalance(walletpixabalance)
    addWalletListener(); 
  }, []);


  const onTokenChange = async (num) => {
    setToken(num)
    setAreYouOwner("")
    setTokenURI(pleasewait)
    setRank("") 
    setAreYouOwner("please wait... ⏱️") 
    const { tokenwiz} = await tokenURIfinder(num, walletAddress);   
    setTokenURI("https://storage.googleapis.com/pixawyverns/" + tokenwiz + "PWYV.gif")
    setRank(displayRank)
    setAreYouOwner(ownership)
  };


  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintWargPressed = async () => { //TODO: implement
      const { status } = await mintWarg(); 
  };

  const onMintThreeWargPressed = async () => { //TODO: implement
      const { status } = await mintThreeWarg();
  };

  const onMintWargPixaPressed = async () => { //TODO: implement
      const { status } = await mintPixaNFT(walletAddress); 
  };

  const onClaimPressed = async () => { //TODO: implement
      const { status } = await claimVortical();
  };

  const onClaimLoopPressed = async () => { //TODO: implement
      const { status } = await claimVorticalLoop();
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkDopplers(name);
    setWizStatus(wizstatus);  
  };

  const onUpgrade1Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 1);
  };

  const onUpgrade2Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 2);
  };

  const onUpgrade3Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 3);
  };

  const onUpgrade4Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 4);
  };

  const onUpgrade5Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 5);
  };


  
  return (
    <div className="Minter">
<div id="stars"></div>
<div id="stars2"></div>
<div id="stars3"></div>
<h5>By using this webpage you agree to the terms & conditions outlined at the bottom of this page. Disclaimer: Use this site at your own risk.</h5>       

<h1>
          <div>
      <img src={pixapng} alt="Image" height={45} width={100} border={0}/>
      </div>
</h1>
      <h2> <a href="https://wizards.pixanft.com" rel="noreferrer">  🧙‍♂️</a>  <a href="https://witches.pixanft.com" rel="noreferrer">  🧙‍♀️</a> <a href="https://wyverns.pixanft.com" rel="noreferrer">  🐲</a> <a href="https://wargs.pixanft.com" rel="noreferrer">  🐺</a>  <a href="https://brews.pixanft.com" rel="noreferrer">  🍺</a> <a href="https://limitededitions.pixanft.com" rel="noreferrer">  🖼️</a> </h2>
      <h5>Click an emoji to navigate to other pages!</h5>         
      <h2> <a href="https://tokens.pixanft.com" rel="noreferrer">  💰</a>  <a href="https://relics.pixanft.com" rel="noreferrer">  🗡️</a> <a href="https://wizardao.pixanft.com" rel="noreferrer">  🎩</a> <a href="https://tomes.pixanft.com" rel="noreferrer">  📖</a> <a href="https://land.pixanft.com" rel="noreferrer">  🏡</a> <a href="https://badges.pixanft.com" rel="noreferrer">  📛</a> </h2>
           
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>


      <h1>💎 Mint 💎</h1>
      <h1>Pixa NFTs</h1>
      <h1>_________</h1>

<h4>Welcome to the PixaRealm! Here you can discover and collect unique non-fungible tokens (NFTs) representing magical creatures and relics from our fantastical world. Our NFTs are created by our founders <a href="https://twitter.com/kryptonika" rel="noreferrer"> Kryptonika</a> and <a href="https://twitter.com/metamunch_eth" rel="noreferrer"> Metamunch</a>! Each NFT is a one-of-a-kind digital asset. From powerful PixaWizards and their noble PixaWyverns, to fearsome PixaWitches and their loyal PixaWargs, there is something for every collector in our realm. And don't forget about the legendary relics, like the Frost Blade and the Ruby Bow, which have shaped the history of the PixaRealm. Finally, cap off your collection with a frosty PixaBrew, but enjoy responsibly! Join us on this magical journey and start your collection today!</h4>

<h4>Scroll down for links to mint these mystical NFTs!⬇</h4>

<h1>_________</h1>

<h1>⬇️</h1>


<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h1 id="relic">🔮  The  🔮</h1>
<h1 id="relic">Charm Crystal</h1>
<h1 id = "relic">_________</h1>
<h5>Release date: Sept 2021</h5>
<h5> The charm crystal is a mysterious and powerful artifact from the "Relics of the PixaRealm" collection. The relic NFTs are ERC721 tokens minted on Ethereum, are free mints but are time-limited. According to legend, the crystal holds the key to unlocking the secrets of the ancient pixawizards.</h5>
<h1>
<div>
<img id = "relic" src={charmgif} alt="Image" height={200} width={200} border={10}/>
</div>
</h1>
<h4><a href="https://relics.pixanft.com" rel="noreferrer"> Mint Charm Crystals</a></h4>
<h4><a href="https://opensea.io/collection/relics-of-the-pixarealm" rel="noreferrer"> OpenSea</a></h4>



<h1 id="relic">_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>


<h1 id="warg">🐺  The  🐺</h1>
<h1 id="warg">PixaWargs</h1>
<h1 id="warg">_________</h1>
<h5>Release date: May 2022</h5>
<h5>The PixaWargs are a limited collection of 500 ERC721 NFTs minted on Ethereum, and are the companions of the PixaWitches, known for their upgradable Power Gem trait and fearsome loyalty.</h5>
<h1>
<div>
<img id="warg" src={wargsgif} alt="Image" height={200} width={200} border={10}/>
</div>
</h1>
<h4><a href="https://wargs.pixanft.com" rel="noreferrer"> Mint PixaWargs</a></h4>
<h4><a href="https://opensea.io/collection/pixawargsofficial" rel="noreferrer"> OpenSea</a></h4>




<h1 id="warg">_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h1 id="witch">🧙‍♀️  The  🧙‍♀️</h1>
<h1 id="witch">PixaWitches</h1>
<h1 id="witch">_________</h1>
<h5>Release date: October 2021</h5>
<h5>The PixaWitches are a limited collection of 2000 ERC721 NFTs minted on Ethereum, and are known for their upgradable Rank Band trait and their shear power.</h5>
<h1>
<div>
<img id="witch" src={witchesgif} alt="Image" height={233} width={200} border={10}/>
</div>
</h1>
<h4><a href="https://witches.pixanft.com" rel="noreferrer"> Mint PixaWitches</a></h4>
<h4><a href="https://opensea.io/collection/pixawitches" rel="noreferrer"> OpenSea</a></h4>


<h1 id="witch">_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h1 id="wyvern">🐲 The 🐲</h1>
<h1 id="wyvern">PixaWyverns</h1>
<h1 id="wyvern">_________</h1>
<h4> -- SOLD OUT --</h4>
<h5>Release date: April 2021</h5>
<h5>The PixaWyverns are a limited collection of 250 ERC721 NFTs minted on Ethereum, and are known for being noble companions to the PixaWizards.</h5>
<h1>
<div>
<img id="wyvern" src={wyvernsgif} alt="Image" height={200} width={200} border={10}/>
</div>
</h1>
<h4><a href="https://opensea.io/collection/pixawyverns" rel="noreferrer"> OpenSea</a></h4>


<h1 id="wyvern">_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h1 id="wizard">🧙‍♂️  The  🧙‍♂️</h1>
<h1 id="wizard"> PixaWizards </h1>
<h1 id="wizard">_________</h1>
<h4>-- SOLD OUT --</h4>
<h5>Release date: February 2021</h5>
<h5>The PixaWizards are a limited collection of 1000 ERC721 NFTs minted on Ethereum, and are known for their leadership... and their affinity for PixaBrews.</h5>
<h1>
<div>
<img id="wizard" src={wizardsgif} alt="Image" height={233} width={200} border={10}/>
</div>
</h1>
<h4><a href="https://opensea.io/collection/pixawizards" rel="noreferrer"> OpenSea</a></h4>

<h1 id="wizard">_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>







    



<h3>Important Links:</h3>

<h4><a href="https://linktr.ee/pixanft" rel="noreferrer">  Linktree</a> </h4>

<h4> <a href="https://opensea.io/PixaDeployer?tab=created" rel="noreferrer">  OpenSea Collections</a> </h4>

<h4><a href="https://discord.gg/pixa" rel="noreferrer">  Discord</a> </h4>

<h4><a href="https://twitter.com/pixa_nft" rel="noreferrer">  Twitter </a></h4>

<br></br>


<h3>Terms & Conditions:</h3>

<h9>(applies to all Pixa sites & smart contracts) - Use this site at your own risk. Pixa is not liable for any losses from the use of this site or associated smart contracts. Your purchase of Pixa NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from Pixa is meant for entertainment purposes only. Pixa makes no warranties regarding future value, express or implied, to the public or to buyers. Pixa does not offer refunds on purchased NFTs. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. Pixa NFTs and $PIXA tokens are not currency and do not convey ownership in Pixa or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in Pixa (formerly known as PixaLyfe), future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your Pixa NFT. There is no guarantee that the PixaRealm platform will actually be developed, although we guarantee that it is our intention to do so.</h9>

<h1>
          <div>
      <img src={pixagif} alt="Image" height={200} width={200} border={0}/>
      </div>
</h1>
    </div>
  );
};



export default Minter;
